import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import Img from "gatsby-image";
import {
    fontSize14,
    fontSize16,
    fontSize20,
    fontSize36,
    fontSize28,
} from "../style/fonts";
import { white } from "../style/colors";
import { mediaMobile, mediaMaxPageWidth, mediaSmall } from "../style/shared";
import { halfMaxContentWidth } from "../style/constants";
import TelLink from "../components/TelLink";

const textStyle = css`
    p {
        ${fontSize16};
        color: ${white};
        margin-bottom: 8px;

        ${mediaSmall} {
            ${fontSize14};
        }
    }

    ul {
        ${fontSize16};
        line-height: 1.5;
        color: ${white};
        padding-left: 24px;
        margin-bottom: 8px;

        ${mediaSmall} {
            ${fontSize14};
        }
    }

    h1 {
        ${fontSize36};
        font-weight: 700;
        color: ${white};
        margin: 50px 0 28px;

        ${mediaMobile} {
            ${fontSize28};
        }
    }
    h2 {
        ${fontSize20};
        font-weight: 700;
        color: ${white};
        margin: 36px 0 20px;
    }
    a {
        color: ${white};
    }
`;

const backgroundStyle = css`
    background-color: #234173;
    padding-left: calc(50% - ${halfMaxContentWidth}px);

    ${mediaMaxPageWidth} {
        padding-left: 32px;
    }
    ${mediaSmall} {
        padding-left: 0;
    }
`;

const topStyle = css`
    padding: 50px 32px 0 0;
    ${mediaSmall} {
        padding: 24px 24px 0 24px;
    }
    ${mediaMobile} {
        padding: 16px 16px 0 16px;
    }
`;

const columns = css`
    display: flex;
    flex-direction: row;

    ${mediaSmall} {
        flex-direction: column;
    }
`;

const textColumn = css`
    min-width: 50%;
    flex: auto;
    padding-bottom: 64px;
    padding-right: 30px;

    ${mediaSmall} {
        width: 100%;
        box-sizing: border-box;
        padding: 0 24px 24px 24px;
    }
    ${mediaMobile} {
        padding: 0 16px 16px 16px;
    }
`;

const imageColumn = css`
    overflow: hidden;
    align-self: flex-end;
    ${mediaSmall} {
        height: 400px;
    }
    ${mediaMobile} {
        align-self: flex-start;
    }
`;

const contactStyle = css`
    margin-top: 32px;
`;

export default ({ data }) => (
    <React.Fragment>
        <Helmet>
            <html lang="de" />
            <title>
                Wir suchen einen Steuerfachangestellten / Steuerfachwirt (m/w/d)
                in Bad Vilbel
            </title>
            <meta
                name="description"
                content="Wir suchen einen Steuerfachangestellten / Steuerfachwirt (m/w/d) in Bad Vilbel"
            />
        </Helmet>
        <div css={[textStyle, backgroundStyle]}>
            <div css={[topStyle]}>
                <h2>Wir suchen zum nächstmöglichen Zeitpunkt einen</h2>
                <h1>Steuerfachangestellten / Steuerfachwirt (m/w/d)</h1>
            </div>
            <div css={columns}>
                <div css={textColumn}>
                    <h2>Was du von uns erwarten kannst:</h2>
                    <ul>
                        <li>
                            Persönliche Mandantenbetreuung und individuelles,
                            eigenverantwortliches Arbeiten
                        </li>
                        <li>
                            Eine angenehme und kollegiale Arbeitsatmosphäre in
                            einem super Team von Kollegen/innen und Inhaber
                        </li>
                        <li>
                            Einen modernen Arbeitsplatz, der auf einem hohen
                            technischen Stand ist
                        </li>
                        <li>Lichtdurchflutete 2-Mitarbeiter-Büros</li>
                        <li>Flexible Arbeitszeiten / Work-Life-Balance</li>
                        <li>
                            Attraktive Vergütung und weitreichende
                            Sozialleistungen
                        </li>
                        <li>Stetige Fortbildungsmöglichkeiten</li>
                        <li>Unbefristetes Arbeitsverhältnis</li>
                        <li>
                            Gute Verkehrsanbindung sowohl mit dem PKW als auch
                            mit den öffentlichen Verkehrsmitteln (S6, Bus 30 und
                            X97, VilBus)
                        </li>
                        <li>Nahe Parkmöglichkeiten</li>
                        <li>
                            Zentrale Lage des Büros (Südbahnhof Bad Vilbel),
                            Einkaufsmöglichkeiten in direkter Nachbarschaft
                        </li>
                        <li>Kostenfreie Getränke und Obst</li>
                        <li>30 Tage Urlaub</li>
                        <li>Duz Kultur</li>
                        <li>Homeoffice</li>
                    </ul>
                    <h2>Dein Aufgabengebiet bei uns:</h2>
                    <ul>
                        <li>Erstellung der laufenden Finanzbuchhaltung</li>
                        <li>Erstellung von Lohnbuchhaltungen</li>
                        <li>Bearbeitung von Jahresabschlüssen</li>
                        <li>
                            Bearbeitung von betrieblichen und privaten
                            Steuererklärungen
                        </li>
                        <li>
                            Ansprechpartner für die Mandanten in allen Bereichen
                        </li>
                    </ul>
                    <h2>Das bringst du mit:</h2>
                    <ul>
                        <li>
                            Eine abgeschlossene Ausbildung zum/zur
                            Steuerfachangestellten/Steuerfachwirt
                        </li>
                        <li>Praxiserfahrung</li>
                        <li>Verantwortungsbewusstsein</li>
                        <li>
                            Eigenständige, sorgfältige und zielorientierte
                            Arbeitsweise
                        </li>
                        <li>Sehr gute Datev-Kenntnisse</li>
                        <li>
                            Sicherer Umgang mit den gängigen
                            Microsoft-Anwendungen (Excel/Word/Outlook)
                        </li>
                    </ul>
                    <h2>Wir freuen uns auf dich!</h2>
                    <p>
                        Bitte sprich uns an oder sende uns deine
                        aussagekräftigen Bewerbungsunterlagen:
                    </p>
                    <p css={contactStyle}>
                        Oliver Schmitt Steuerberatungsgesellschaft mbH
                        <br />
                        Kassler Str. 30
                        <br />
                        61118 Bad&nbsp;Vilbel
                        <br />
                        <br />
                        Tel.:&nbsp;
                        <TelLink to="800660" /> Fax:&nbsp;
                        <TelLink to="800670" />
                    </p>
                </div>
                <div css={imageColumn}>
                    <Img
                        fixed={data.jobsBg.childImageSharp.fixed}
                        imgStyle={{ objectPosition: "0 0" }}
                        alt="Flugzeuge die sich in den Himmel schrauben"
                    />
                </div>
            </div>
        </div>
    </React.Fragment>
);

export const query = graphql`
    query {
        jobsBg: file(
            relativePath: {
                eq: "maria-noel-rabunal-cantero-HQzQB-dPpgo-unsplash.jpg"
            }
        ) {
            childImageSharp {
                fixed(height: 800, quality: 70) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
